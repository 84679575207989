<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="续租延期"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <div class="list_box">

              <div class="contentBox">
                <div class="plate_num_box">
                  <div class="plate_num_box_tit" style="text-align: left">
                    请输入要续租的车牌
                  </div>
                  <div class="plate_num_box_input">
                    <div
                      class="plate_wrap"
                      v-for="(item, index) in plateNumber"
                      :key="index"
                      @click.stop="bindFocus(index)"
                    >
                      <div
                        class="plate_wrap_input"
                        v-if="index !== 7"
                        :class="[
                          input_index === index ? ' section-dashed' : '',
                        ]"
                      >
                        {{ item }}
                      </div>
                      <!-- 2 -->
                      <div
                        class="plate_wrap_input new_input"
                        v-if="index === 7"
                        :class="[
                          input_index === index ? ' section-dashed' : '',
                        ]"
                      >
                        <div v-if="newFlag" placeholder="">
                          {{ item }}
                        </div>
                        <img
                          v-if="!newFlag"
                          src="../../../assets/img/indexnewnengyuan@2x.png"
                          alt=""
                        />

                        <span v-if="!newFlag" class="new_vh">新能源</span>
                      </div>
                    </div>
                  </div>
                  <!-- 历史记录展示 -->
                  <div class="history">
                    <!-- 图标 -->
                    <span
                      class="historyIcon"
                      v-show="historyNumList && historyNumList.length > 0"
                    >
                      <van-icon class="iconfont icon-lishi" />
                    </span>
                    <div
                      class="historyItem"
                      v-for="item in historyNumList"
                      :key="item.id"
                      data-info="item"
                      @click="clickHistoryNum(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <van-divider />
                  <!-- <div class="notVhNumReceiveBox">
                    <span class="notVhNumReceiveClass"> 温馨提示： </span>
                    <div class="notVhNumReceiveTip" style="font-size: 14px">
                      请确保要续租的车辆车牌号已添加至【我的车辆】，且在当前时段租期内
                    </div>
                  </div> -->
                </div>
                <div class="btnOperation">
                  <!-- <van-button
                    @click="nextBtn"
                    class="query_btn"
                    color="#4797EA"
                    type="info"
                  >
                    下一步
                  </van-button> -->
                  <van-button @click="nextBtn" class="query_btn">
                    <span class="query_btn_text">下一步</span>
                  </van-button>
                </div>
              </div>
              <!-- <div class="emptyWrapBox" v-else>
                <div class="emptyBox">
                  <img src="../../../assets/img/emptyImg@2x.png" alt="" />
                </div>
                <div class="emptyTip">
                  <div class="text">当前还没有可续租的月租车辆</div>
                  <div class="text">先去<span class="monthPage" @click="goMonthPage">【月租办理】</span>中办理月租吧</div>
                </div>
              </div> -->

        </div>
      </div>
    </div>
    <!-- 键盘 -->
    <car-keyboard
      ref="keyboard"
      :show.sync="showKeyboard"
      @getResult="setNumber"
      :plateNumber.sync="plateNumber"
      :index.sync="input_index"
      @setIndex="setIndex"
    ></car-keyboard>
    <van-dialog
      v-model="showMonthEmpty"
      title="提示"
      :message="formattedMessage"
      show-cancel-button
      confirmButtonText="去添加"
      cancelButtonText="暂不添加"
      confirm-button-color="#4797EA"
    >
    </van-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import * as util from '../../../api/util'
import * as constant from '../../../api/constant'
import { mapState, mapGetters } from 'vuex'
import CarKeyboard from '../../../components/CarKeyboard.vue'
import { Dialog, Toast } from 'vant'
export default {
  components: {
    CarKeyboard
  },
  data () {
    return {
      active: 0,
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      plate: '',
      historyNumList: [],
      showKeyboard: false,
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      newFlag: false,
      index: '',
      originalMessage:
        '未检测到您有可办理的续租/延期车辆。\n 如需办理，请前往【我的车辆】中添加车辆',
      showMonthEmpty: false,
      haveMonthVhFlag: false,
      haveGroupVhFlag: false,
      groupVhList: [], // 分组车的列表
      monthNum: 1,
      selGroupList: [],

      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('vhColumns', this.vhColumns)
      console.log('queryLogHistory', this.queryLogHistory)
      if (this.queryLogHistory && this.queryLogHistory.length) {
        // vhColumns [] 每次初次加载，显示自己绑定的车辆加上一辆不重复的原历史记录
        // this.historyNumList = [...this.queryLogHistory,...this.vhColumns]
        // this.historyNumList = [...new Set([...this.queryLogHistory, ...this.vhColumns])];
        this.historyNumList = [...new Set([...this.queryLogHistory, ...this.vhColumns])].slice(0, 4)
      }
      if (!this.vhColumns || this.vhColumns.length === 0) {
        // this.showMonthEmpty = true
        // Dialog.confirm({
        //   title: '提示',
        //   message: '未检测到您有可办理的续租/延期车辆。如需办理，请前往【我的车辆】中添加车辆',
        //   confirmButtonText: '去添加',
        //   cancelButtonText: '暂不添加',
        //   confirmButtonColor: '#4797EA'
        // })
        //   .then(() => {
        //   // on confirm
        //     this.$router.push('/myCar')
        //   })
        //   .catch(() => {
        //   // on cancel
        //   })
      } else {
        // this.get_coupon(this.active)
        // TODO 存在已绑定的车辆，则展示在历史记录区域，去重。
        // this.historyNumList = this.queryLogHistory
      }
    })
  },
  computed: {
    ...mapGetters(['vhColumns', 'queryLogHistory', 'openID', 'sid']),
    formattedMessage () {
      return this.originalMessage.replace(/\n/g, '<br>')
    }
  },
  methods: {
    timeFormatter (para) {
      return common.format(new Date(para), 'yyyy-MM-dd')
    },
    onRefresh () {
      // this.billForMonthData = []
      // this.finished = false
      // this.loading = true
      // this.onLoad()
    },
    onLoad () {
      // 监听到即执行
      console.log('滑到底了')

      // //   再次调用 查询 下一页数据
      // if (this.refreshing) {
      //   // 下拉刷新
      //   this.pageOption.page_no = 1
      //   this.doQuery()
      // } else {
      //   if (this.errorFlag) {
      //     // 说明上次加载失败 现在重新加载
      //     this.pageOption.page_no = 1
      //     this.errorFlag = false
      //     this.doQuery()
      //     return false
      //   }
      //   // 触底加载
      //   // if (this.billForMonthData.length >= this.pageOption.total) {
      //   //   this.finished = true
      //   // } else {
      //   this.pageOption.page_no = this.pageOption.page_no + 1
      //   this.doQuery()
      //   // }
      // }
    },
    checkboxChange (row) {
      console.log('选择的数据是', row)

      if (row.checked) {
        this.selGroupList = [row]
        // 选中当前数据
        this.groupVhList.forEach((x) => {
          if (x.id !== row.id) {
            x.checked = false
          }
        })
      } else {
        this.selGroupList = []
        this.groupVhList.forEach((x) => {
          x.checked = false
        })
      }
      this.checkBoxDisabled(row)
      this.monthNum = 1
    },
    checkBoxDisabled (item) {
      console.log(this.selGroupList)
      if (this.selGroupList.length === 1) {
        if (Number(this.selGroupList[0].id) === Number(item.id)) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    // 根据续租月数，更新续租时间
    countMonthDate (item) {
      console.log('续租', item)
      console.log('续租月数', this.monthNum)
      if (!item.startDate || !item.endDate) {
        return this.$toast('开始日期或结束日期不规范')
      }
      // 将startDate字符串转换为Date对象
      const startDate = new Date(item.startDate)
      const endDate = new Date(item.endDate)

      // 添加续租月数
      endDate.setMonth(endDate.getMonth() + this.monthNum)
      // 如果endDate小于startDate（考虑跨年情况），则增加年份
      if (endDate < startDate) {
        endDate.setFullYear(endDate.getFullYear() + 1)
      }
      // 将endDate转换回字符串格式
      const endDateStr = endDate.toISOString().split('T')[0] // ISO格式，日期部分

      return item.startDate + ' - ' + endDateStr
    },
    goMonthPage () {},
    nextBtn () {
      // 检查车牌号是否填写完整
      const isPlateNumberComplete = this.plateNumber.slice(0, 7).every(Boolean)
      if (!isPlateNumberComplete) {
        this.$toast('请输入完整的车牌号')
        return
      }
      // 拼接车牌号
      this.plate = this.plateNumber.join('')
      console.log('当前输入的车牌', this.plate)
      // 更新历史记录
      this.updateHistoryVh()
      // 获取车辆信息
      this.getHaveMonthVh()
    },
    // 切换Tab
    onChange () {
      console.log(this.active)
      this.pageOption.page_no = 1
      this.queryTab()
    },
    queryTab () {
      if (this.active === 0) {
        // 月租车辆续费延期
        // this.queryParkPayRecord()
      } else {
        // 分组车辆续费延期
        this.getHaveGroupVh()
      }
    },
    clickHistoryNum (data) {
      // console.log('点击的历史记录车牌为', data)
      // console.log('正在展示的车牌为', this.plateNumber)
      const plateNum = data
      // 如果点击的车牌号和输入框的不一样 则填入  否则 什么也不做
      const inpNum = this.plateNumber.join('')
      if (plateNum === inpNum) {
        return
      }
      const nowArr = plateNum.split('')
      const bakArr = JSON.parse(JSON.stringify(this.plateNumberBak))
      for (let i = 0; i < nowArr.length; i++) {
        if (nowArr[i] !== '') {
          bakArr[i] = nowArr[i]
        }
      }
      this.plateNumber = bakArr
      this.setNumber(this.plateNumber)
    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    setIndex (data) {},
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    onInput (key) {
      this.para.pass = (this.para.pass + key).slice(0, 6)
    },
    onDelete () {
      this.para.pass = this.para.pass.slice(0, this.para.pass.length - 1)
    },

    inputFocus (e) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = parseInt(e.target.dataset.index)
      } else {
        this.input_index = parseInt(e.target.dataset.index)
      }
    },
    updateHistoryVh () {
      if (this.plate.length === 7 || this.plate.length === 8) {
        const arrOld = this.historyNumList || []
        const arrNew = []
        arrNew.push(this.plate, ...arrOld)
        // 去重
        const qcArr = [...new Set(arrNew)]
        // console.log('qcArr'.qcArr)
        // 最大四个
        if (qcArr.length === 5) {
          qcArr.splice(qcArr.length - 1, 1)
        }
        this.historyNumList = qcArr
        // 保存vuex
        this.$store.commit(
          constant.keyQueryLogHistoryVuex,
          this.historyNumList
        )
      }
    },
    // 查询是否存在可续租的月租车
    async getHaveMonthVh () {
      // TODO
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      // 查询成功则跳转到续租详情
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.vh.get_vh_charge_info,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: 'yuen03',
            openid: this.openID,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            plate_number: this.plate,
            version: 'v2'
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('续租延期查询 res', JSON.stringify(data))
        // TODO 判断是固定车还是分组车

        data.monthOrGroup = 'month'
        sessionStorage.setItem('postponeDetail', JSON.stringify(data))

        Toast.clear()

        if (data.code === 10000) {
          this.$router.push({
            path: '/postponeDetail'
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              console.error('Navigation error1:', err)
              // Handle the error as needed
            }
          })
        } else {
          Toast.fail(data.msg || '查询出错')
        }
      } catch (e) {
        console.log('getHaveMonthVh-catch', e)
        this.$toast('查询异常')
      }

      // setTimeout(() => {
      // const data = {
      //   id: 0,
      //   vh_p_number: '浙A12345',
      //   startDate: '2024-01-01',
      //   endDate: '2024-09-01',
      //   vh_p_price: 100,
      //   monthOrGroup: this.active === 0 ? 'month' : 'group'
      // }
      // // const targetRoute = {
      // //   path: '/postponeDetail',
      // //   query: {
      // //     id: data.id,
      // //     vh_p_number: data.vh_p_number,
      // //     startDate: data.startDate,
      // //     endDate: data.endDate,
      // //     vh_p_price: data.vh_p_price,
      // //     monthOrGroup: data.monthOrGroup
      // //   }
      // // }

      // // const { id, vh_p_number, startDate, endDate, vh_p_price, monthOrGroup } = data

      // this.$router.push({
      //   path: '/postponeDetail',
      //   query: {
      //     id: data.id,
      //     vh_p_number: data.vh_p_number,
      //     startDate: data.startDate,
      //     endDate: data.endDate,
      //     vh_p_price: data.vh_p_price,
      //     monthOrGroup: data.monthOrGroup
      //   }
      // }).catch(err => {
      //   if (err.name !== 'NavigationDuplicated') {
      //     console.error('Navigation error1:', err)
      //     // Handle the error as needed
      //   }
      // })
      // }, 1000)
    },
    // 查询是否存在可续租的分组车
    getHaveGroupVh () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      setTimeout(() => {
        const arr = [
          {
            id: 0,
            groupName: '分组1',
            checked: false,
            startDate: '2024-01-01',
            endDate: '2024-09-01',
            vhPlates: [
              '浙AFZ001',
              '浙AFZ002',
              '浙AFZ003',
              '浙AFZ002',
              '浙AFZ003',
              '浙AFZ002',
              '浙AFZ003'
            ]
          },
          {
            id: 1,
            groupName: '分组2',
            checked: false,
            startDate: '2024-05-01',
            endDate: '2024-11-01',
            vhPlates: ['浙BFZ001', '浙BFZ002', '浙BFZ003']
          }
        ]
        this.groupVhList = arr
        Toast.clear()
      }, 1000)
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.plate_num_box {
  // height: 12.5rem;
  background-color: #fff;
  // box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
  // border-radius: 0.08rem;
  // margin-left: 0.01rem;
  display: flex;
  flex-direction: column;
  // padding: 0 0 1.0625rem;
  padding: 0.16rem;
  margin-top: 0.08rem;
  .plate_num_box_tit {
    font-size: 0.16rem;
    text-align: left;
    font-weight: bolder;
    margin-top: 0.16rem;
    margin-bottom: 0.16rem;
    margin-left: 0.1rem;
  }
  .plate_num_box_input {
    // width: 3.24rem;
    margin-top: 0.11rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 0.08rem 0 0.08rem;
    .plate_wrap {
      width: 0.34rem;
      height: 0.44rem;
      background-color: rgba(233, 238, 255, 1);
      display: flex;
      align-items: center;
      text-align: center;
      .section-dashed {
        height: 0.44rem;
        border: 0.01rem solid #1989fa;
      }

      .plate_wrap_input {
        height: 0.44rem;
        width: 0.34rem;
        text-align: center;
        font-size: 0.16rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .new_vh {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.1rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.14rem;
          margin: 0.01rem 0 0 -0.01rem;
        }
      }
      .new_input {
        background-color: rgba(233, 255, 235, 1);
        img {
          width: 0.18rem;
          height: 0.17rem;
          margin: 0 0.06rem 0 0.05rem;
        }
      }
    }
  }
  .history {
    margin-top: 0.12rem;
    display: flex;
    justify-content: flex-start;
    // flex-wrap: wrap;
    align-items: center;
    .historyIcon {
      margin-left: 0.08rem;
      margin-bottom: 0.08rem;
      font-size: 0.1rem;
    }
    .historyItem {
      background-color: #f4f4f4;
      padding: 0.05rem 0.09rem;
      border-radius: 0.12rem;
      font-size: 0.1rem;
      color: #333;

      margin-right: 0.04rem;
      margin-left: 0.08rem;
      margin-bottom: 0.08rem;
    }
  }
}

.notVhNumReceiveBox {
  display: flex;
  margin-top: 0.08rem;
  margin-bottom: 0.19rem;
  flex-direction: column;
  align-items: flex-start;
}

.notVhNumReceiveClass {
  font-size: 12px;
  color: #666;
}
.notVhNumReceiveTip {
  color: #666;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  margin-top: 0.1rem;
  text-indent: 2em;
}
/deep/ .van-tabs__nav {
  background-color: #f8f7fc;
}
/deep/ .van-tabs__line {
  background-color: #4470fc;
}
/deep/ .van-divider {
  margin: 0;
}
.emptyWrapBox {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .emptyBox {
    width: 2.3rem;
    width: 1.72rem;
    margin-top: 0.23rem;
  }
}

.emptyBox img {
  width: 100%;
  width: 100%;
}
.emptyTip {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
  .text {
    color: #666;
    font-size: 11px;
    .monthPage {
      color: #4797ea;
    }
  }
}
.btnOperation {
  display: flex;
  justify-content: center;
  .query_btn {
    width: 100%;
    background-color: #4797ea;
    border-radius: 0.04rem;
    margin-top: 0.3rem;
    // display: flex;
    // flex-direction: column;
    // padding: 0.1rem 1.21rem 0.1rem 1.21rem;
    margin-left: 0.16rem;
    margin-right: 0.16rem;
    .query_btn_text {
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.16rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.22rem;
    }
  }
}

// 分组车
.groupWrapBox {
  margin-top: 0.1rem;
  .groupBoxItem {
    background-color: #fff;
    padding: 0.06rem 0.16rem 0.16rem;
    margin-bottom: 0.16rem;
    .names {
      display: flex;
      .groupName {
        font-size: 0.12rem;
        color: #333;
        font-weight: bold;
        line-height: 0.2rem;
        margin-left: 0.06rem;
      }
    }

    .dates {
      font-size: 0.14rem;
      line-height: 0.17rem;
      padding-left: 0.22rem;
      text-align: left;
    }
    .plates {
      display: flex;
      margin-top: 0.1rem;
      margin-bottom: 0.06rem;
      padding-left: 0.22rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .platesShowItem {
        padding: 0.05rem 0.1rem;
        color: #000;
        font-size: 0.1rem;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        background-color: #f4f4f4;
        border-radius: 0.12rem;
      }
    }
    .showWhenSelGroup {
      .monthFooter {
        display: flex;
        justify-content: space-between;
        margin-top: 0.08rem;
        .leftTit {
          font-size: 0.12rem;
          color: #999;
        }
      }
      .monthFooterDate {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        .rightValue {
          font-size: 0.12rem;
          color: #333;
        }
      }
    }
  }
}
</style>
